import classNames from 'classnames';

import { useSettingsStore } from '../../store';

const MonitoringServicesView = () => {
    const { theme } = useSettingsStore();

    return (
        <main
            className={classNames(['view', theme.current === 'dark' && 'dark'])}
        >
            <div className={'viewContent'}>
                <h1 className={'view-title'}>Solicitudes de emergencia</h1>
            </div>
        </main>
    );
};

export default MonitoringServicesView;
