import { ReactNode } from 'react';

import HomeView from './views/home/home.view';
import PetitionDetailsView from './views/petition-details/petition-details.view';
import UsersView from './views/users/users.view';
import UserDetailsView from './views/user-details/user-details.view';
import CreatePetitionView from './views/create-petition/create-petition.view';
import PetitionsHistoryView from './views/petitions-history/petitions-history.view';
import SettingsView from './views/settings/settings.view';
import ProfileView from './views/profile/profile.view';
import OperatorsView from './views/operators/operators.view';
import CreateOperatorView from './views/create-operator/create-operator.view';
import MonitoringServicesView from './views/monitoring-services/monitoring-services.view';

export const Views: { path: string; element: ReactNode }[] = [
    {
        path: '/',
        element: <HomeView />,
    },
    {
        path: '/petitions',
        element: <PetitionsHistoryView />,
    },
    {
        path: '/create-petition',
        element: <CreatePetitionView />,
    },
    {
        path: '/petitions/:id',
        element: <PetitionDetailsView />,
    },
    {
        path: '/users',
        element: <UsersView />,
    },
    {
        path: '/users',
        element: <UsersView />,
    },
    {
        path: '/users/:id',
        element: <UserDetailsView />,
    },
    {
        path: '/settings',
        element: <SettingsView />,
    },
    {
        path: '/profile',
        element: <ProfileView />,
    },
    {
        path: '/operators',
        element: <OperatorsView />,
    },
    {
        path: '/create-operator',
        element: <CreateOperatorView />,
    },
    {
        path: '/monitoring-services',
        element: <MonitoringServicesView />,
    },
];
