import HomeIcon from '../../icons/home.icon';
import EmergencyIcon from '../../icons/emergency.icon';
import UsersIcon from '../../icons/users.icon';
import SettingsIcon from '../../icons/settings.icon';
import LocationIcon from '../../icons/location.icon';
import UserIcon from '../../icons/user.icon';

export const menuItems = [
    {
        text: 'Inicio',
        icon: <HomeIcon size={24} />,
        path: '/',
        roles: ['coordinator', 'admin', 'super_admin', 'cmc'],
    },
    {
        text: 'Historial solicitudes',
        icon: <EmergencyIcon color={'#565454'} size={24} />,
        path: '/petitions',
        roles: ['coordinator', 'admin', 'super_admin', 'cmc'],
    },
    {
        text: 'Reporte de solicitudes',
        icon: <EmergencyIcon color={'#565454'} size={24} />,
        path: '/petitions-report',
        targetBlank: true,
        roles: ['coordinator', 'admin', 'super_admin', 'cmc'],
    },
    {
        text: 'Lista de usuarios',
        icon: <UsersIcon size={24} />,
        path: '/users',
        roles: ['coordinator', 'admin', 'super_admin', 'cmc'],
    },
    {
        text: 'Ajustes',
        icon: <SettingsIcon size={24} />,
        path: '/settings',
        roles: ['coordinator', 'admin', 'super_admin', 'cmc'],
    },
    {
        text: 'Crear emergencia',
        icon: <LocationIcon size={24} />,
        path: '/create-petition',
        roles: ['coordinator', 'admin', 'super_admin', 'cmc'],
    },
    {
        text: 'Monitoreos',
        icon: <LocationIcon size={24} />,
        path: '/monitoring-services',
        roles: ['coordinator', 'admin', 'super_admin', 'cmc'],
    },
    {
        text: 'Operadores',
        icon: <UsersIcon size={24} />,
        path: '/operators',
        roles: ['coordinator', 'admin', 'super_admin'],
    },
    {
        text: 'Mi perfil',
        icon: <UserIcon size={24} />,
        path: '/profile',
        roles: ['coordinator', 'admin', 'super_admin', 'cmc'],
    },
];
