const COORDINATOR_SET = new Set(['coordinator', 'admin', 'super_admin']);

const ADMIN_SET = new Set(['admin', 'super_admin']);

const isAdmin = (roleName: string): boolean => {
    return ADMIN_SET.has(roleName);
};

const isCoordinator = (roleName: string): boolean => {
    return COORDINATOR_SET.has(roleName);
};

export { isAdmin, isCoordinator };
