import axios, { AxiosError } from 'axios';

import { ResponseTO } from '../types/response-to.type';
import { PaginatedQuery } from '../types/paginated-query.type';

import { addQueryParams, handleResponseError } from './service';

const BASE_URL = process.env.REACT_APP_PETITIONS_URL;
const BASE_ADMIN_URL = process.env.REACT_APP_PETITIONS_ADMIN_URL;
const AUTH_TOKEN = process.env.REACT_APP_API_TOKEN;

const getActives = async (): Promise<ResponseTO> => {
    try {
        const response = await axios.get(`${BASE_URL}/petitions/active`, {
            headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
        });
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

export type GetAllFilters = PaginatedQuery & {
    filters?: Record<string, string>;
};

const getAll = async (queryParams?: GetAllFilters): Promise<ResponseTO> => {
    try {
        const params = {
            ...queryParams!.filters,
            page: queryParams!.page,
            count: queryParams!.count,
        };
        const inlineParams = addQueryParams(params);
        const response = await axios.get(
            `${BASE_URL}/petitions${inlineParams}`,
            {
                headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getById = async (id: number): Promise<ResponseTO> => {
    try {
        const response = await axios.get(`${BASE_URL}/petitions/${id}`, {
            headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
        });
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const updateStatus = async (data: {
    id: number;
    status: number;
}): Promise<ResponseTO> => {
    try {
        const { id, status } = data;
        const response = await axios.patch(
            `${BASE_URL}/petitions/${id}/status`,
            { status },
            { headers: { Authorization: `Bearer ${AUTH_TOKEN}` } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const cancelPetition = async (
    id: number,
    reason?: number
): Promise<ResponseTO> => {
    try {
        const response = await axios.delete(
            `${BASE_URL}/petitions/${id}?reason=${reason}`,
            {
                headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getCancellationReasons = async (): Promise<ResponseTO> => {
    try {
        const response = await axios.get(`${BASE_URL}/reasons?isMobile=false`, {
            headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
        });
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const autocompleteAddress = async (address: string): Promise<ResponseTO> => {
    try {
        const response = await axios.get(
            `${BASE_URL}/geocode/autocomplete?text=${address}`,
            {
                headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const reverseGeocode = async ({
    lat,
    lng,
}: {
    lat: number;
    lng: number;
}): Promise<ResponseTO> => {
    try {
        const response = await axios.get(
            `${BASE_URL}/geocode/reverse?lat=${lat}&lng=${lng}`,
            {
                headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getPlaceDetails = async (id: string): Promise<ResponseTO> => {
    try {
        const response = await axios.get(`${BASE_URL}/geocode/places/${id}`, {
            headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
        });
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getPetitionMotives = async (accessToken: string): Promise<ResponseTO> => {
    try {
        const response = await axios.get(
            `${BASE_ADMIN_URL}/motives?active=true`,
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

export type PetitionCreation = {
    userId: number;
    location: { lat: number; lng: number; address: string };
    motives?: number[];
};

const createPetition = async (
    data: PetitionCreation,
    accessToken: string
): Promise<ResponseTO> => {
    try {
        const response = await axios.post(`${BASE_ADMIN_URL}/petitions`, data, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const addOperators = async (
    data: {
        id: number;
        operators: number[];
    },
    accessToken: string
): Promise<ResponseTO> => {
    try {
        const response = await axios.put(
            `${BASE_ADMIN_URL}/petitions/${data.id}/operators`,
            {
                operators: data.operators,
            },
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const updateMotives = async (
    data: { id: number; motives: number[] },
    accessToken: string
): Promise<ResponseTO> => {
    try {
        const response = await axios.put(
            `${BASE_ADMIN_URL}/petitions/${data.id}/motives`,
            {
                motives: data.motives,
            },
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const addComment = async (
    data: {
        id: number;
        content: string;
    },
    accessToken: string
): Promise<ResponseTO> => {
    try {
        const response = await axios.post(
            `${BASE_ADMIN_URL}/petitions/${data.id}/comments`,
            {
                content: data.content,
            },
            {
                headers: { Authorization: `Bearer ${accessToken}` },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

export const petitionSdk = {
    getActives,
    getAll,
    getById,
    updateStatus,
    getCancellationReasons,
    cancelPetition,
    autocompleteAddress,
    reverseGeocode,
    getPlaceDetails,
    getPetitionMotives,
    createPetition,
    addOperators,
    addComment,
    updateMotives,
};
