import styles from '../reason-selection/reason-selection.module.scss';
import { PETITION_CHANNELS } from '../../../constants/petition-channels';

interface Props {
    onSelect: (value: string) => void;
}

const SourceSelection = ({ onSelect }: Props) => {
    return (
        <section>
            <label>Canal de comunicación</label>
            <div className={styles.formSection}>
                <span>
                    Escoge el canal por el cual el usuario nos contactó.
                </span>
                <select onChange={(ev) => onSelect(ev.target.value)}>
                    {PETITION_CHANNELS.map((channel, idx) => (
                        <option
                            key={`petition-channel-${idx}`}
                            value={channel.value}
                        >
                            {channel.text}
                        </option>
                    ))}
                </select>
            </div>
        </section>
    );
};

export default SourceSelection;
